import axios from 'axios';
import baseUrlConfig from '../config/baseUrl';

function RequestInterceptor(config) {
  return config;
}

function RequestInterceptorError(error) {

  return Promise.reject(error);
}

function ResponseInterceptor(config) {
  return config;
}

function ResponseInterceptorError(error) {

  console.log('error in service', error);
  return Promise.reject(error.response);
}

class HttpService {
  constructor() {
    this.axios = axios.create();
  }

  async fetch(payload, cancelToken) {
    try {
      const config = {};
      config.headers = {
        'Content-Type': 'application/json',
        ...payload.headers
      };
      config.headers = payload.auth
        ? { ...config.headers, Authorization: 'Bearer ' + localStorage.getItem('token') }
        : { ...config.headers };
      // config.headers = payload.hasAuth
      //   ? { ...config.headers, Authorization: "Bearer " + localStorage.getItem("token") }
      //   : config.headers;
      // config.url = payload.url.includes(baseUrlConfig.baseUrl) ? payload.url : baseUrlConfig.baseUrl + payload.url;
      config.url = payload.url.includes('http') ? payload.url : baseUrlConfig.baseUrl + payload.url;

      //config.url = payload.url;

      config.cancelToken = cancelToken;
      config.method = payload.method ? payload.method : 'GET';
      config.data = JSON.stringify(payload.data);
      config.params = payload.params;

      //  config.auth = payload.withCredentials ? payload.auth : {};
      //  config.withCredentials = payload.withCredentials;
      const data = await this.axios(config);
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request Cancelled!');
      }
      throw error;
    }
  }

  httpInterceptors() {
    this.axios.interceptors.request.use(RequestInterceptor, RequestInterceptorError);
    this.axios.interceptors.response.use(ResponseInterceptor, ResponseInterceptorError);
  }
}

const httpService = new HttpService();

export default httpService;
